import { Link } from "gatsby"
import React from "react"
import { PATH } from "../../constants"

const Menu = () => {
  const menu = [
    { text: "スクールについて", path: PATH.ABOUT, bgColor: "bg-LightGreen" },
    { text: "レッスン・料金", path: PATH.LESSONS, bgColor: "bg-LightBlue" },
    { text: "よくある質問", path: PATH.FAQ, bgColor: "bg-LightRed" },
    { text: "お問い合わせ", path: PATH.CONTACT, bgColor: "bg-LightYellow" },
  ]
  return (
    <div className="grid grid-cols-2 gap-5 mt-16 md:grid-cols-4">
      {menu.map(item => {
        return (
          <Link
            key={item.text}
            to={item.path}
            className={`py-12 text-center font-ZenMaruGathic text-lg md:text-xl text-DeepGreen ${item.bgColor}`}
          >
            {item.text}
          </Link>
        )
      })}
    </div>
  )
}

export default Menu
