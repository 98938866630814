import { Link } from "gatsby"
import React from "react"

type Props = {
  title: string
  to?: string
  onClick?: () => void
}

const Button = ({ title, onClick, to }: Props) => {
  return (
    <div className="mx-auto font-semibold rounded bg-Yellow text-DeepGreen w-fit">
      {to ? (
        <Link className="block px-5 py-2 md:px-7" to={to}>
          {title}
        </Link>
      ) : (
        <button className="px-5 py-2 md:px-7" onClick={onClick}>
          {title}
        </button>
      )}
    </div>
  )
}

export default Button
