import React from "react"
import { PATH } from "../../constants"
import Button from "../Button"

const TrialLesson = () => {
  return (
    <div className="mx-[calc(50%_-_50vw)] bg-LightYellow py-12 px-4 mt-16">
      <div className="grid max-w-lg grid-cols-2 gap-4 mx-auto">
        <div className="mx-auto">
          <Button title="体験レッスン" to={PATH.TRIAL_LESSON} />
        </div>
        <div className="mx-auto">
          <Button title="翻訳サービス" to={PATH.TRANSLATION} />
        </div>
      </div>
    </div>
  )
}

export default TrialLesson
