import React from "react"
import Map from "../about/Map"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const Description = () => {
  const list = [
    "沼田駅から車で３分、徒歩１３分",
    "入会金0円、リーズナブルな月謝＆チケット制",
    "小学生から大人までどなたでもお申し込みOK",
  ]
  return (
    <>
      <div className="mt-12 space-y-2 text-xl text-center">
        <p>
          オーストラリア人講師マーク先生
          <br className="md:hidden" />
          が教える英会話教室
        </p>
      </div>
      <p className="my-8 text-3xl text-center text-DeepGreen font-UbuntuMono">
        WOMBAT ENGLISH
        <span className="block ml-3 text-2xl md:inline-block">
          （ウォンバット・イングリッシュ）
        </span>
      </p>
      <div className="max-w-xl p-5 mx-auto mt-8 space-y-2 border-2 md:mt-16 border-Yellow">
        {list.map((item, index) => (
          <div className="flex items-start" key={item}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="mt-1 mr-2"
              color="#183A1D"
              size="lg"
            />
            <div>
              {item}
              <div className={`text-sm ${index !== 0 ? "hidden" : ""}`}>
                ⇨詳しいアクセスは
                <Link
                  to="/about#access"
                  className="underline hover:no-underline"
                >
                  こちら
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="h-64 max-w-xl mx-auto my-14">
        <Map defaultZoom={14} />
      </div>
      <div className="max-w-2xl mx-auto text-center">
        利根沼田では珍しいオーストラリア人講師の英会話教室で、ネイティブから英語を学ぶことができます。
        英会話をイチから習いたい初心者から、英語力を維持したいしたい上級者まで経験豊富な講師がみなさんの英語学習をサポートします。
        <br />
        <br />
        一緒に楽しく英語を話しましょう！
      </div>
    </>
  )
}

export default Description
