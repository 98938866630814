import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Description from "../components/top/Description"
import Menu from "../components/top/Menu"
import TrialLesson from "../components/top/TrialLesson"
import logo from "../components/top/hero.gif"
// import Popup from "../components/Popup"

const IndexPage = () => (
  <Layout>
    <Seo />
    <img src={logo} alt="loading..." className="mx-auto mt-8 md:max-w-3xl" />
    <Description />
    <Menu />
    <TrialLesson />
    {/* お知らせのポップアップ一旦コメントアウト */}
    {/* <Popup /> */}
  </Layout>
)

export default IndexPage
